/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import Account from './Account';
import Swal from 'sweetalert2';
import Search from './Search';
import Settings from './SettingsTop';
import {
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Notifications from './Notifications';
import Stream from './Stream';
import Help from './Help';
import Year from './Year';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import client from 'src/utils/GQLFeeClient';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import CompanyClient from 'src/utils/GQLCompanyClient';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import './styles.css';
import Rules from 'src/Rules.json'
import {
  useDashboardContext
} from 'src/contexts/DashboardContext';
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#002F6D'
  }
})(AppBar);

const handleClick = () => {
  Swal.fire({
    title: 'Feature Locked',
    icon: 'warning',

    text:
      'This feature is available in our Advanced Plan. Please view details of our Advanced Plan and contact us.',
    customClass: {
      popup: 'swal2-popup-blur',
      backdrop: 'swal2-backdrop-blur',
      container: 'swal2-container-zindex'
    }
  });
};
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor:'#ffffff'
  },
  root1: {
    display: 'flex',
    paddingLeft: 256,
    transitionDuration: 500,
    backgroundColor:'#ffffff'

  },

  desktopDrawer: {
    width: 256,
    height: '100%',
    backgroundColor:Rules.nav_top_bar.navbar_background_color,
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        status={item.status}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        status={item.status}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    // marginRight: theme.spacing(1)
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

var Owner = {
  // subheader: 'FeePay Management Panel',
  items: [
    {
      title: 'Dashboard',
      href: '/app/fee/dashboard',
      icon: 'material-symbols-light:dashboard-outline',
      // items:[
      //   {
      //     title: 'Dashboard',
      //     href: '/app/fee/dashboard',
      //     icon: 'material-symbols-light:dashboard-outline',
         
      //   },
        // {
        //   title: 'FeePe',
        //   href: '/app/fee/dashboard/FeePe',
        //   icon: 'material-symbols-light:dashboard-outline',
         
        // },
      // ]
    },
    {
      title: 'Admissions',
      href: '/app/admission',
      icon: 'fluent:people-community-20-regular',
      items: [
        // {
        //   title: 'Applicant Tracker',
        //   href: '/app/admission/schedule',
        //   icon: 'carbon:event-schedule'

        // },
        {
          title: 'Tracker',
          href: '/app/new_admission/admission_schedules',
          icon: 'carbon:event-schedule'
        },
        // {
        //   title: 'Students',
        //   href: '/app/admission/allStudents',
        //   icon: 'ph:student',
        // },
        {
          title: 'Students',
          href: '/app/admin/students/admission/Student/live',
          icon: 'ph:student',
        },
        {
          title: 'Approvals',
          href: '/app/admission/approvals',
          icon: 'ic:baseline-transform'
        },
        {
          title: 'Certificates',
          href: '/app/admission/certificates',
          icon: 'fluent:certificate-16-regular'
        },
        {
          title: 'Reports',
          href: '/app/admission/reports',
          icon:'tabler:report-analytics'
          // icon: AccountBalanceIcon,
        }
      ]
    },
    {
      title: 'Fee Management',
      href: '/app/fee',
      icon: `healthicons:money-bag-outline`,
      items: [
        {
          title: 'Fee Tracker',
          href: '/app/fee/schedule',
          icon: 'carbon:event-schedule'
          // icon: CalendarMonthRoundedIcon
        },
        {
          title: 'Collect Fee',
          href: '/app/admin/students/fee/Student/live',
          icon:'tdesign:money'
        },
        {
          title: 'Pay Records',
          href: '/app/accounts'
          ,
          icon:'material-symbols-light:payments-outline'
        },
        {
          title: 'Transactions',
          href: '/app/fee/transactions',
          icon: 'carbon:cics-transaction-server-zos'
        },

        {
          title: 'Reports',
          href: '/app/fee/reports',
          icon:'tabler:report-analytics'
          // icon: AccountBalanceIcon,
        },
        {
          title: 'Configuration',
          href: '/app/fee/settings/fee_settings',
          icon:'carbon:tools'
          // icon: AccountBalanceIcon,
        }
      ]
    },

    {
      title: 'Billing',
      href: '/app/admin/accounts',
      icon:'fluent:feed-24-regular'
      ,
      items: [
        // {
        //   title: 'Purchase Bill',
        //   href: '/app/billing/purchase'
        // },
        {
          title: 'Customers',
          href: '/app/BillingCRM/Billing/Billing_crm_view',
          icon: 'ion:people-outline'
        },
        {
          title: 'Sale Bill',
          href: '/app/fee/transactions/parties/create_order',
          icon:'mingcute:bill-2-line'
          // info: LockRoundedIcon
        },
        {
          title: 'Transactions',
          href: '/app/billing/transactions',
          icon: 'carbon:cics-transaction-server-zos'

          // icon: AccountBalanceIcon,
        }
      ]
    },
    {
      title: 'Library',
      href: '/app',
      icon: 'ion:library-outline'
      ,
      items:[
        {
          title: 'Members',
          href: '/app/LibraryCRM/Library/Library_crm_view/crm/Library',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Circulation',
          href: '/app/Circulation/Library/Circulation_view/Circulation/Library',
          icon: 'mdi:circular-arrows'
        },
        {
          title: 'Books',
          href: '/app/Asset/Library/Asset_view/Asset/Library',
          icon: 'fluent:web-asset-24-regular'
        },

        {
          title: 'Book Copies',
          href: '/app/AssetCopy/Library/Asset_copy_view/Asset_Copies/Library',
          icon: 'ep:document-copy'
        },
        {
          title: 'Events',
          href: '/app/Event/Library/EventView/Event/Library',
          icon: 'bx:calendar'
        },
        {
          title: 'Orders',
          href: '/app/Orders/Library/Orders_view/Orders/Library',
          icon: 'lets-icons:order-light'
        },
        {
          title: 'Transactions',
          href: '/app/Transactions/Library/Transactions_view/Transactions/Library',
          icon: 'carbon:cics-transaction-server-zos'
        },
        // {
        //   title: 'Certificates',
        //   href: '/app/Certificates/Library/Certificates_view/Certificates/Library',
        //   icon: 'fluent:certificate-16-regular'
        // },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Library/check_in_out_view/Timesheet/Library',
          icon: 'icon-park-outline:reverse-operation-out'
        },
        {
          title: 'Device',
          href: '/app/CRM/device/Library/check_in_out_device_view/Device/Library',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
       
        {
          title: 'Reports',
          href: '/app/reports/CRM/Library',
          icon: 'tabler:report-analytics'
        },
      ]
      // icon: AccountBalanceIcon,
    },
    {
      title: 'Hostel',
      href: '/app',
      icon: 'clarity:building-line'
      ,
      items:[
        {
          title: 'Members',
          href: '/app/HostelCRM/Hostel/Hostel_crm_view/crm/Hostel',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Asset',
          href: '/app/Asset/Hostel/Asset_Hostel_view/Asset/Hostel',
          icon: 'fluent:web-asset-24-regular'
        },
        {
          title: 'Events',
          href: '/app/Event/Hostel/EventView/Event/Hostel',
          icon: 'bx:calendar'
        },
        // {
        //   title: 'Orders',
        //   href: '/app/Orders/Hostel/Orders_hostel_view/Orders/Hostel',
        //   icon: 'lets-icons:order-light'
        // },
        // {
        //   title: 'Transactions',
        //   href: '/app/Transactions/Hostel/Transactions_Hostel_view/Transactions/Hostel',
        //   icon: 'carbon:cics-transaction-server-zos'
        // },
        // {
        //   title: 'Certificates',
        //   href: '/app/Certificates/Hostel/Certificates_Hostel_view/Certificates/Hostel',
        //   icon: 'fluent:certificate-16-regular'
        // },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Hostel/Hostel_check_in_out_view/Timesheet/Hostel',
          icon: 'icon-park-outline:reverse-operation-out'
        },
        {
          title: 'Device',
          href: '/app/CRM/device/Hostel/Hostel_check_in_out_device_view/Device/Hostel',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
        
       
      ]
      // icon: AccountBalanceIcon,
    },
    {
      title: 'Alumni',
      href: '/app',
      icon: 'ph:student'
      ,
      items:[
        {
          title: 'Students',
          href: '/app/AlumniCRM/Alumni/Alumni_crm_view/crm/Alumni',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Events',
          href: '/app/Event/Alumni/EventView/Event/Alumni',
          icon: 'bx:calendar'
        },
       
      
       
      ]
      // icon: AccountBalanceIcon,
    }

  // {  
  //   title: 'Notification Logs',
  //   href: '/app/admin/notifications',
  //   icon: 'zondicons:notifications-outline',
  // },
    // {
    //   title: 'Accounts',
    //   href: '/app/accounts',
    //   icon: 'ion:notifications-outline'
    //   ,
    //   items: [
    //     {
    //       title: 'Accounts',
    //       href: '/app/accounts'
    //       // icon: AccountBalanceIcon,
    //     },
    //     {
    //       title: 'Transactions',
    //       href: '/app/accounts/transactions'
    //       // icon: AccountBalanceIcon,
    //     }
    //   ]
    // },

    // {
    //   title: 'Reports',
    //   href: '/app/fee/reports',
    //   icon:'tabler:report-analytics'
    //   ,
    //   items: [
    //     {
    //       title: 'Admission Reports',
    //       href: '/app/admission/reports',
    //       icon: 'fluent:people-community-20-regular',

    //       // icon: AccountBalanceIcon,
    //     },
    //     {
    //       title: 'Fee Reports',
    //       href: '/app/fee/reports',
    //       icon: `healthicons:money-bag-outline`,

    //       // icon: AccountBalanceIcon,
    //     }
    //   ]
    // }
  ]
};

var Fee = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/fee/dashboard',
      icon: 'material-symbols-light:dashboard-outline'

    },

    {
      title: 'Fee Management',
      href: '/app/fee',
      icon: `healthicons:money-bag-outline`,
   
      items: [
        {
          title: 'Fee Tracker',
          href: '/app/fee/schedule',
          icon: 'carbon:event-schedule'
          // icon: CalendarMonthRoundedIcon
        },
        {
          title: 'Collect Fee',
          href: '/app/admin/students/fee/Student/live',
          icon:'tdesign:money'
        },
        {
          title: 'Pay Records',
          href: '/app/accounts',
          icon:'material-symbols-light:payments-outline'
        },
        {
          title: 'Transactions',
          href: '/app/fee/transactions',
          icon: 'carbon:cics-transaction-server-zos'
        },

        {
          title: 'Reports',
          href: '/app/fee/reports',
          icon:'tabler:report-analytics'
          // icon: AccountBalanceIcon,
        },
        {
          title: 'Configuration',
          href: '/app/fee/settings/fee_settings',
          icon:'carbon:tools'
          // icon: AccountBalanceIcon,
        }
      ]
    },

    {
      title: 'Billing',
      href: '/app/admin/accounts',
      icon:'fluent:feed-24-regular'
      ,
      items: [
        {
          title: 'Customers',
          href: '/app/BillingCRM/Billing/Billing_crm_view',
          icon: 'ion:people-outline'
        },
        {
          title: 'Sale Bill',
          href: '/app/fee/transactions/parties/create_order',
          icon:'mingcute:bill-2-line'

          // info: LockRoundedIcon
        },
        {
          title: 'Transactions',
          href: '/app/billing/transactions',
          icon: 'carbon:cics-transaction-server-zos'
          // icon: AccountBalanceIcon,
        }
      ]
    }
  ]
};

var Admission = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/fee/dashboard',
      icon: 'material-symbols-light:dashboard-outline'

    },
    {
      title: 'Admissions',
      href: '/app/fee',
      icon: 'fluent:people-community-20-regular',
      
      items: [
        {
          title: 'Applicant Tracker',
          href: '/app/new_admission/admission_schedules',
          icon: 'carbon:event-schedule'
        },
        {
          title: 'Students',
          href: '/app/admin/students/admission/Student/live',
          icon: 'ph:student',
        },
        {
          title: 'Approvals',
          href: '/app/admission/approvals',
          icon: 'ic:baseline-transform'
        },
        {
          title: 'Certificates',
          href: '/app/admission/certificates',
          icon: 'fluent:certificate-16-regular'
        },
        {
          title: 'Reports',
          href: '/app/admission/reports',
          icon:'tabler:report-analytics'
          // icon: AccountBalanceIcon,
        }
      ]
    }
  ]
};
// var Billing = {
 
//   items: [
//     {
//       title: 'Billing',
//       href: '/app/billing',
//       icon: 'ion:notifications-outline'
//       ,
//       items:[
//         {
//           title: 'Invoice',
//       href: '/app/billing/Invoice',
//         }
//       ]
//       // icon: AccountBalanceIcon,
//     }
//   ]
// };
var Library_owner = {
 
  items: [
    {
      title: 'Library',
      href: '/app',
      icon: 'ion:library-outline'
      ,
      items:[
        {
          title: 'Student',
          href: '/app/LibraryCRM/Library/Library_crm_view/crm/Library',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Library/check_in_out_view/Timesheet/Library',
          icon: 'icon-park-outline:reverse-operation-out'
        },
        {
          title: 'Device',
          href: '/app/CRM/device/Library/check_in_out_device_view/Device/Library',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
        {
          title: 'Reports',
          href: '/app/reports/CRM/Library',
          icon: 'tabler:report-analytics'
        },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
var Library = {
 
  items: [
    {
      title: 'Library',
      href: '/app',
      icon: 'ion:library-outline'
      ,
      items:[
        {
          title: 'Members',
          href: '/app/LibraryCRM/Library/Library_crm_view/crm/Library',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Circulation',
          href: '/app/Circulation/Library/Circulation_view/Circulation/Library',
          icon: 'mdi:circular-arrows'
        },
        {
          title: 'Books',
          href: '/app/Asset/Library/Asset_view/Asset/Library',
          icon: 'fluent:web-asset-24-regular'
        },
        {
          title: 'Book Copies',
          href: '/app/AssetCopy/Library/Asset_copy_view/Asset_Copies/Library',
          icon: 'ep:document-copy'
        },
        {
          title: 'Events',
          href: '/app/Event/Library/EventView/Event/Library',
          icon: 'bx:calendar'
        },
        // {
        //   title: 'Orders',
        //   href: '/app/Orders/Library/Orders_view/Orders/Library',
        //   icon: 'lets-icons:order-light'
        // },
        // {
        //   title: 'Transactions',
        //   href: '/app/Transactions/Library/Transactions_view/Transactions/Library',
        //   icon: 'carbon:cics-transaction-server-zos'
        // },
        // {
        //   title: 'Certificates',
        //   href: '/app/Certificates/Library/Certificates_view/Certificates/Library',
        //   icon: 'fluent:certificate-16-regular'
        // },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Library/check_in_out_view/Timesheet/Library',
          icon: 'icon-park-outline:reverse-operation-out'
        },
       
        // {
        //   title: 'Device',
        //   href: '/app/CRM/device/Library/check_in_out_device_view/Device/Library',
        //   icon: 'material-symbols:assistant-device-outline-rounded'
        // },
        {
          title: 'Reports',
          href: '/app/reports/CRM/Library',
          icon: 'tabler:report-analytics'
        },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
var Library_device = {
 
  items: [
    {
      title: 'Library Device',
      href: '/app',
      icon: 'ion:library-outline'
      ,
      items:[
        
        {
          title: 'Timesheet Device',
          href: '/app/CRM/device/Library/check_in_out_device_view/Device/Library',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
var Hostel = {
 
  items: [
    {
      title: 'Hostel',
      href: '/app',
      icon: 'clarity:building-line'
      ,
      items:[
        {
          title: 'Student',
          href: '/app/HostelCRM/Hostel/Hostel_crm_view/crm/Hostel ',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Hostel/Hostel_check_in_out_view/Timesheet/Hostel',
          icon: 'icon-park-outline:reverse-operation-out'
        },
        // {
        //   title: 'Timesheet Device',
        //   href: '/app/admin/device/check_in_out_device_view',
        //   icon: 'material-symbols:assistant-device-outline-rounded'
        // },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
var Hostel_owner = {
 
  items: [
    {
      title: 'Hostel',
      href: '/app',
      icon: 'clarity:building-line'
      ,
      items:[
        {
          title: 'Student',
          href: '/app/HostelCRM/Hostel/Hostel_crm_view/crm/Hostel ',
          icon: 'heroicons:user-group'
        },
        {
          title: 'Timesheet',
          href: '/app/CRM/check/Hostel/Hostel_check_in_out_view/Timesheet/Hostel',
          icon: 'icon-park-outline:reverse-operation-out'
        },
        {
          title: 'Device',
          href: '/app/CRM/device/Hostel/Hostel_check_in_out_device_view/Device/Hostel',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
var Hostel_device = {
 
  items: [
    {
      title: 'Hostel Device',
      href: '/app',
      icon: 'clarity:building-line'
      ,
      items:[
        
        {
          title: 'Timesheet Device',
          href: '/app/CRM/device/Hostel/Hostel_check_in_out_device_view/Device/Hostel',
          icon: 'material-symbols:assistant-device-outline-rounded'
        },
      ]
      // icon: AccountBalanceIcon,
    }
  ]
};
const TopBar = ({ className,  onMobileNavOpen, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const { user } = useAuth();
  const classes = useStyles();

  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const { open, setOpen } = useDashboardContext();
  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const getStreams = useCallback(async () => {
    try {
      const getstudents = gql`
        query MyQuery {
          get_fee_setting {
            account_section
            schollarship_section
          }
        }
      `;

      const { data } = await client
        .query({
          query: getstudents,

          fetchPolicy: 'network-only'
        })
        .then(res => {
          //console.log(res);
          return res;
        });

      if (isMountedRef.current) {
        setSettings(data.get_fee_setting);
      }
    } catch (err) {
      //console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getStreams();
  }, [getStreams]);

  const [image, setImage] = useState(null);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  let folderName = user['custom:institution_id'];
  var bucketName = 'erpinstitution';
  let img;
  let logo;
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
      .getObject({
        Bucket: bucketName,
        Key: image_path
      })
      .promise()
      .then(res => {
        if (res) {
          console.log('logo', res);
          return res;
        } else {
          return null;
        }
      })
      .catch(err => {
        console.log(err);
      });
    return img;
  }

  const getCustomers = useCallback(async () => {
    try {
      const get_image = gql`
        query get_setup {
          get_setup {
            _id
            institution_logo
          }
        }
      `;
      var image_path =
        encodeURIComponent(folderName) +
        '/' +
        encodeURIComponent('logo') +
        '/' +
        user['custom:institution_id'];

      img = await get_logo(image_path);
      if (img === undefined || img === null) {
        logo = null;
      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        setImage(logo);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

 

  // window.addEventListener('storage', function(e) {
  //   console.log('storage fired');
  //   // this.fetchData();
  // });
  // console.log(window.dispatchEvent(new Event('storage')));

  // window.addEventListener('storage', console.log);

  window.dispatchEvent(new Event('storage'));

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Box pt={2.5} pb={1.4} px={1.5}>
          <Box mt={-1} ml={1.2} display="flex">
            {open === true && (
             <>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton, open)}
              >
                <MenuIcon fontSize="large" style={{color:'#ffffff'}} />
              </IconButton>
               <Box mr={2} />
               {open && <Box mt={1}>
                  <Typography
                    variant="h5"
                    style={{ color: '#ffffff', fontSize: '20px' }}
                  >
                    Zivin
                  </Typography>
                </Box>

                }
                
</>
            )}
            {open === false && (
              <>
              <Box mr={1}/>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton1, open)}
              >
                <MenuOpenIcon fontSize="large" style={{color:'black'}}/>
              </IconButton></>
            )}
           
          </Box>
        </Box>{' '}
        <Box px={2} pt={0.5} pb={0}>
          {(user.user_group === 'owner' || user.user_group === 'admin') && (
            <List
              key={Owner.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Owner.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Owner.items,
                pathname: location.pathname
              })}
            </List>
          )}
          {user.user_group === 'fee' && (
            <List
              key={Fee.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Fee.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Fee.items,
                pathname: location.pathname
              })}
            </List>
          )}
          {user.user_group === 'admission' && (
            <List
              key={Admission.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Admission.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Admission.items,
                pathname: location.pathname
              })}
            </List>
          )}

          {/* {process.env.REACT_APP_ENV === 'test' && (
            <List
              key={Billing.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Billing.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Billing.items,
                pathname: location.pathname
              })}
            </List>
          )} */}
                   {
                   (user.user_group === 'library') && 
                   (
           <List
           key={Library.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Library.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Library.items,
             pathname: location.pathname
           })}
         </List>
          )} 
          {/* {(user.user_group === 'owner' ) && (
           <List
           key={Library_owner.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Library_owner.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Library_owner.items,
             pathname: location.pathname
           })}
         </List>
          )} 
            {(user.user_group === 'owner' ) && (
           <List
           key={Hostel_owner.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Hostel_owner.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Hostel_owner.items,
             pathname: location.pathname
           })}
         </List>
          )}  */}
                             {(user.user_group === 'library_device' )&& (
           <List
           key={Library_device.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Library_device.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Library_device.items,
             pathname: location.pathname
           })}
         </List>
          )} 
           
           {/* {(user.user_group === 'owner' ) && (
           <List
           key={Hostel.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Hostel.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Hostel.items,
             pathname: location.pathname
           })}
         </List>
          )} 
               {(user.user_group === 'owner' )&& (
           <List
           key={Hostel_device.subheader}
           subheader={
             <ListSubheader disableGutters disableSticky>
               {Hostel_device.subheader}
             </ListSubheader>
           }
         >
           {renderNavItems({
             items: Hostel_device.items,
             pathname: location.pathname
           })}
         </List>
          )}  */}
          <Box mt={-1} />
        </Box>
      </PerfectScrollbar>

      <Box flexGrow={1} />
      <Box pt={2} />
      <Divider />
      {/* <Box p={1} pl={5} borderRadius="borderRadius"
        //  bgcolor="background.dark"
         >
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/app/admin/help_section"
              className={classes.action}
            >
              Resolve My Issue
            </Link>
          </Box> */}

      <Divider />
    </Box>
  );

  const Rightcontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map(section => (
            <Box>
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderRightNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>

              {/* <List
                key={seetingsacademic.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {seetingsacademic.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: seetingsacademic.items,
                  pathname: location.pathname
                })}
              </List> */}
            </Box>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />

        <Box p={2}></Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ColorAppBar className={open ? classes.root1 : classes.root} {...rest}>
        <Toolbar className={classes.toolbar}>
         
          {open === false && (
            <IconButton
            style={{color:'#000000'}}
            aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuOpenRoundedIcon fontSize="large" />
            </IconButton>
          )}

          {/* <Hidden mdDown>
            <Avatar src={'/static/company.png'}></Avatar>
            <Box mr={1} />
            <Avatar src={image}></Avatar>
          </Hidden> */}
          <Stream image={image} />

          <Box ml={2} flexGrow={1} />
          <Year />

          {/* <Search /> */}
          {/* <Contacts /> */}
          <Notifications />
          <Help />
          {(user.user_group === 'admin' || user.user_group === 'owner') && (
            <Settings />
          )}
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </ColorAppBar>
      {/* {open === true && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open)}
            >
              <MenuRoundedIcon fontSize="large" />
            </IconButton>
          )} */}
      {
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {content}
        </Drawer>
      }
      {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
